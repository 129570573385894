@import url("./root.css");

:root {
  color-scheme: light;
}

body {
  margin: 0;
  font-family: var(--fontFamily) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--fontFamily-display) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Dark Mode over write */

body.TurnTodark .bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

body.TurnTodark .text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

/* Firebase Emulator Warning */

.firebase-emulator-warning {
  display: none !important;
}
