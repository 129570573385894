.dropdown-menu-center {
    top: 120% !important;
    right: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, 0) !important;
    -o-transform: translate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;
}

.cursor-event {
    cursor: pointer;
}