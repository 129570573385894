/* .nav-items-right{
    margin-left: auto;
} */
.heading{
    padding-top: 200px;
}
.img{
    padding-top: 150px;
}
.explore-event{
    margin-left: -20px;
}
.icon{
    text-align: center;
}
.network{
    margin-left: 100px;
}
.upcoming{
    width: 500px;
    height: 380px;
}
.share-icon{
   margin-left: 150px;
}
.image{
    width: 400px;
    height: 250px;
}
.stories{
    width: 400px;
    height: 420px;
    margin-left: 15px;
}