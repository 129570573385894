@import url("../../../../root.css");
.rec-item-wrapper {
  max-width: 320px;
}

.custom-arrow {
  cursor: pointer;
  align-items: center !important;
  margin-top: 150px;
  color: var(--bs-tertiary) !important;
}

.custom-arrow:hover {
  color: var(--bs-primary) !important;
}

.rec-dot {
  visibility: hidden;
}

.carousel_custom .sc-gsnTZi.rec.rec-item-wrapper {
  /* Temp fix */
  display: block;
}

@media screen and (max-width: 620px) {
  .custom-arrow {
    display: none;
  }
}

@media screen and (max-width: 828px) {
  .rec-item-wrapper {
    max-width: 100%;
  }
}
