.cursor_pointer {
  cursor: pointer;
}

.close_announcement {
  color: rgba(var(--bs-light-rgb), 0.4) !important;
}

.close_announcement:hover {
  color: rgba(var(--bs-light-rgb), 1) !important;
}
