/* background while auto suggation */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(var(--bs-light-rgb));
  -webkit-box-shadow: 0 0 0 30px rgba(var(--bs-dark-rgb), 1) inset !important;
}

/* webkit */
.TurnTodark input:-webkit-autofill,
body.TurnTodark input:-webkit-autofill:hover,
body.TurnTodark input:-webkit-autofill:focus,
body.TurnTodark textarea:-webkit-autofill,
body.TurnTodark textarea:-webkit-autofill:hover,
body.TurnTodark textarea:-webkit-autofill:focus,
body.TurnTodark select:-webkit-autofill,
body.TurnTodark select:-webkit-autofill:hover,
body.TurnTodark select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(var(--bs-dark-rgb));
  -webkit-box-shadow: 0 0 0 30px rgba(var(--bs-light-rgb), 1) inset !important;
}

.multiLineLabel {
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
}

.multiLineLabel .textMaxLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.multiLineLabel .textMaxOneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.multiLineLabel .textMaxThreeLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}


.description_filter * {
  color: rgb(var(--text-light)) !important;
  background-color: transparent !important;
}

#bannerShare{
    background-image: url(./assets/jpeg/Exclude.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.modal-dialog .modal-content {
  border: none !important;
}
