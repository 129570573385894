.modal-backdrop {
  z-index: 1055 !important ;
}

.btn-cancel {
  display: none !important;
}

.react-form-builder-form div.btn-toolbar {
  justify-content: flex-end;
  margin: 5px;
}

.registrationForm_biz .alert {
  padding-bottom: 4px;
}
