.starting-page-schedule img {
  width: 120px;
  margin: 1em 0;
}
.step-Number {
  width: 80px;
  height: 80px;
  position: relative;
  border: 5px solid #0b4995;
  border-radius: 50%;
  margin: 0 auto 1em;
}
body.TurnTodark .step-Number {
  border-color: #fff;
}
.step-Number h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #0b4995;
  font-weight: 900;
}
body.TurnTodark .step-Number h3 {
  color: #fff;
}
