@import url("../../../../root.css");

.label {
  cursor: pointer;
  width: 50px;
  height: 24px;
}

.ball {
  width: 18px;
  height: 20px;
  top: 1px !important;
  left: 1px !important;
}

.switch_sun {
  color: var(--yellow_color);
}
#darkMoodIndex{
  z-index: 1000000000000000 !important;
}