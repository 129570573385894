@import url("../../../../root.css");

.settingNav{
    width: 270px !important;
}

@media (max-width:984px){   
    .settingNav{
        width: 100% !important;
    }
}
