.react-form-builder-toolbar {
  width: 100% !important ;
  background: transparent;
  margin-top: unset !important ;
}

.react-form-builder-preview {
  padding: 4px;
  width: 100% !important ;
}

.registrationForm_biz .react-form-builder .react-form-builder-preview {
  overflow-y: scroll;
  max-height: 60vh;
  min-height: 60vh;
}

.registrationForm_biz .react-form-builder .react-form-builder-toolbar {
  overflow-y: scroll;
  max-height: 60vh;
}

@media (min-width: 575.98px) {
  .react-form-builder-toolbar {
    width: 100% !important ;
    background: transparent;
    margin-top: unset !important ;
  }

  .react-form-builder-preview {
    padding: 4px;
    width: 100% !important ;
  }
  .registrationForm_biz .react-form-builder .react-form-builder-toolbar,
  .registrationForm_biz .react-form-builder .react-form-builder-preview {
    overflow-y: scroll;
    max-height: 60.5vh;
    min-height: 60.5vh;
  }
}

@media (min-width: 767.98px) {
  .react-form-builder-toolbar {
    width: 25% !important ;
    background: transparent;
    margin-top: unset !important ;
  }

  .react-form-builder-preview {
    padding: 4px;
    width: 75% !important ;
  }
  .registrationForm_biz .react-form-builder .react-form-builder-toolbar,
  .registrationForm_biz .react-form-builder .react-form-builder-preview {
    overflow-y: scroll;
    max-height: 92.5vh;
    min-height: 92.5vh;
  }
}

@media (min-width: 991.98px) {
  .react-form-builder-toolbar {
    width: 25% !important ;
    background: transparent;
    margin-top: unset !important ;
  }

  .react-form-builder-preview {
    padding: 4px;
    width: 75% !important ;
  }
}

@media (min-width: 1199.98px) {
  .react-form-builder-toolbar {
    width: 25% !important ;
    background: transparent;
    margin-top: unset !important ;
  }

  .react-form-builder-preview {
    padding: 4px;
    width: 75% !important ;
  }
}

@media (min-width: 1399.98px) {
  .react-form-builder-toolbar {
    width: 25% !important ;
    background: transparent;
    margin-top: unset !important ;
  }

  .react-form-builder-preview {
    padding: 4px;
    width: 75% !important ;
  }
}
