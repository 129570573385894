.ais-Hits-list {
  list-style: none;
  padding: 0;
}

.ais-SearchBox-input {
  margin-right: 4px;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px;
  background: transparent;
  outline: none;
  margin: 2px;
  width: 50%;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  background: transparent;
  margin-right: 4px;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px 8px;
  background: transparent;
  margin: 4px;
}
