@import url("../../../../root.css");

.brand_pointer {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}
.dropdown-menu {
  left: -6.3em !important;
}
.dropdown-menu .dropdown-item:hover {
  background-color: var(--bs-secondary) !important;
  color: var(--bs-light) !important;
}

.notificationIcon_custom {
  font-size: xx-large;
}


.notification_count{
  position: absolute;
  top: 0;
  right: 10px;
}