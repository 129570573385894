/* todo: all style remove and add to there respective components */
.starFill {
  color: var(--bs-quaternary);
}

.cursor_pointer {
  cursor: pointer !important;
}

#modalIframe {
  color-scheme: light !important;
}
#attendEvent .btn-toolbar .btn {
  background: var(--bs-primary) !important;
  color: #fff !important;
}

body.TurnTodark #attendEvent .rfb-item.SortableItem,
body.TurnTodark #attendEvent .rfb-item.SortableItem .form-group input {
  background: rgb(var(--bizform-light-rgb)) !important;
  color: rgb(var(--bizform-dark-rgb)) !important;
}
