.summary {
    font-size: 14px;
    line-height: 1.5;
}

.summary p.collapse:not(.show) {
    height: 42px !important;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.summary p.collapsing {
    min-height: 42px !important;
}

.summary a.collapsed:after {
    content: '+ Read More';
}

.summary a:not(.collapsed):after {
    content: '- Read Less';
}


.expand_collapse{
    cursor: pointer;
}