.no_cursor {
  cursor: default !important;
}

.markAsRead_eventUpdate {
  cursor: pointer;
  opacity: 0.4;
}

.markAsRead_eventUpdate:hover {
  opacity: 1;
}
