/* Over write text editor */

.demo-option-custom-wide {
  text-decoration: none !important;
}

.editorClassName {
  min-height: 150px;
}

.public-DraftStyleDefault-block {
  margin: 0.2rem 0;
  padding: 0em 1em;
}

.rdw-editor-main {
  min-height: 150px !important ;
  height: auto !important ;
  cursor: text;
}

.rdw-option-wrapper {
  background: transparent !important;
  padding: 12px 6px !important;
}

.rdw-option-wrapper img.dark_editor {
  -webkit-filter: invert(1);
  filter: invert(1);
}

a.rdw-dropdown-selectedtext {
  color: inherit;
  text-decoration: none;
}

.rdw-dropdown-selectedtext {
  font-weight: 500;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: none;
}

.rdw-colorpicker-modal {
  box-shadow: none;
}

.rdw-colorpicker-modal-options {
  overflow-x: hidden;
}

/* dropDown light */
.rdw-dropdown-wrapper,
.rdw-dropdown-wrapper * {
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-light-rgb));
}

.rdw-dropdownoption-default:hover,
.rdw-dropdownoption-active {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-light-rgb));
}

.rdw-dropdown-carettoopen {
  border-top: 6px solid rgba(var(--bs-light-rgb));
}

/* dropDown dark */
body.TurnTodark .rdw-dropdown-wrapper,
body.TurnTodark .rdw-dropdown-wrapper * {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-dark-rgb));
}

body.TurnTodark .rdw-dropdownoption-default:hover,
body.TurnTodark .rdw-dropdownoption-active {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-light-rgb));
}

body.TurnTodark .rdw-dropdown-carettoopen {
  border-top: 6px solid rgba(var(--bs-dark-rgb));
}

body.TurnTodark .rdw-colorpicker-modal {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}
