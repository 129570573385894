
/* Every  Important css style manages from here  */
@font-face {
  font-family: "Larsseit";
  src: local("Larsseit"), url(./font/larsseit/Larsseit.otf) format("opentype");
}

:root {
  /* font */
  --fontFamily: "Inter", -apple-system, BlinkMacSystemFont, San Francisco,
    Segoe UI, Roboto, Helvetica Neue, sans-serif;

  /* --fontFamily-display:  */
  --fontFamily-display: "Larsseit", -apple-system, BlinkMacSystemFont,
    "Segoe UI", sans-serif;

  /* color */
  --yellow_color: yellow;
}