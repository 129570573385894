// Over write bootstrap default with biz connect theme
// over write theme color var
$primary: #0b4995; //biz blue #0b4995
$secondary: #eeeeee; // biz gray #eeeeee
// light mode
$light: #212529;
$dark: #f8f9fa;

// dark mode config added in "./index.css" ( note: In dark mode swaping the light mode value )

@import "../node_modules/bootstrap/scss/_functions";
@import "../node_modules/bootstrap/scss/_variables";

$custom-theme-colors: (
  "tertiary": #f47236,
  "quaternary": #e4164f,
);

$theme-colors: map-merge(
  $theme-colors,
  $custom-theme-colors
); // You should already have this, so no need to copy this part.

// Start copying at the line below
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge(
  $theme-colors-rgb,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-color),
  )
);

$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  "$key",
  "text"
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
// Stop copying at the line above

@import "../node_modules/bootstrap/scss/bootstrap"; // You should already have this as well, so no need to copy this part.
